import './App.sass';
import Bewerben from './Components/Bewerben/Bewerben';

import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Jobs from './Components/Jobs/Jobs';
import Navigation from './Components/Navigation/Navigation';
import Staerken from './Components/Staerken/Staerken';
import Testimonials from './Components/Testimonials/Testimonials';
import Vorteile from './Components/Vorteile/Vorteile'
import Videokanal from './Components/Videokanal/Videokanal'


function App() {

	return (
		<div className="App">
			<Navigation />
			<Header />
			<Vorteile />
			<Staerken />
			<Testimonials />
			<Jobs />
			<Bewerben />
			<Videokanal />
			<Footer />
		</div>
	);
}

export default App;
