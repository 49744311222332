import React from 'react'

import './Bubble.sass'

const Bubble = (props) => {
	return (
		<div className={`bubble ${props.align}`}>
			{props.children}
			<div className="bubble--arrow"></div>
		</div>
	)
}

export default Bubble
