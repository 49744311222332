import React from 'react'

import './Header.sass'
import Button from '../UIElements/Button/Button'

import { FaPhoneAlt} from "react-icons/fa"

import headerImage from '../../images/header-callcenter.jpg'
import topjob from '../../images/topjob.png'
import Bubble from '../UIElements/Bubble/Bubble'

const Header = () => {
	return (
		<header style={{ backgroundImage: `url(${headerImage})` }}>
			<Bubble align="left">
				<h1><strong>Werde</strong> telefonischer <strong>Kundenberater</strong> (m/w/d) – <strong>schnell &amp; unkompliziert!</strong></h1>
			</Bubble>
			<img src={topjob} alt="TOPJOB" width="150" />
			<div className="cta--container">
				<a className="phone--link" href="tel:0800 54 59 95"><FaPhoneAlt /></a>
				<Button href="#bewerben" type="red">Jetzt bewerben</Button>
			</div>
		</header>
	)
} 

export default Header
