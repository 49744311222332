import React from 'react'

import './Staerken.sass'

import { FiHeart, FiPhone, FiSmile, FiMessageSquare } from 'react-icons/fi'
import Button from '../UIElements/Button/Button'



const Staerken = () => {
	return (
		<section id="staerken" className="content">
			<h2>Deine Stärken</h2>
			<ul className="strengths">
				<li><FiHeart/><p>Spaß im Umgang mit Menschen haben</p></li>
				<li><FiPhone/><p>eine angenehme Telefonstimme haben</p></li>				
				<li><FiSmile/><p>eine ausgeprägte Service- und Kundenorientierung haben</p></li>				
				<li><FiMessageSquare/><p>gerne über alle gängigen Kontaktkanäle kommunizieren </p></li>				
			</ul>
			<Button href="#bewerben">Jetzt bewerben!</Button>
		</section>

	)
}

export default Staerken
