import React, { useEffect, useState } from 'react'

import './Testimonials.sass'

import klaus from '../../images/klaus.jpg'
import ramona from '../../images/ramona.jpg'
import krenar from '../../images/krenar.jpg'
import Testimonial from './Testimonial/Testimonial'

const data = [
	{
		text: "Ich arbeite gerne bei KiKxxl, weil die Menschen hier morgens mit einem Lächeln erscheinen. Man hat das Gefühl, dass sie alle gerne her kommen.",
		image: klaus,
		testimonial: "Klaus",
		job: "Kundenberater"
	},
	{
		text: "Ich arbeite gerne bei KiKxxl, weil wir alle wie in einer großen Familie zusammen arbeiten, uns respektieren und wertschätzen.",
		image: ramona,
		testimonial: "Ramona",
		job: "Kundenberaterin"
	},
	{
		text: "​Ich arbeite gerne bei KiKxxl, weil die Benefits einfach klasse sind, vor allem die „Mitarbeiter wirbt Mitarbeiter“ Aktion kommt mir sehr gelegen.",
		image: krenar,
		testimonial: "Krenar",
		job: "Kundenberater"
	},
]

const Testimonials = () => {

	const [activeState, setActiveState] = useState(0)
	
	// eslint-disable-next-line
	useEffect(() => {
		const interval = setInterval(() => {			
			if (activeState < (data.length - 1)) setActiveState(activeState + 1) 
			else setActiveState(0)
		}, 5000);
		return () => clearInterval(interval)
	}, [activeState]) 

	return (
		<section id="testimonials" className="content">
			<div className="testimonial--grid">
				{data.map((testi, index) => (
					<Testimonial
						key={index}
						active={index === activeState ? true : false}
						text={testi.text}
						image={testi.image}
						testimonial={testi.testimonial}
						job={testi.job}
						index={index}
						handleClick={setActiveState}
					/>
				))}
			</div>
			<div className="bullets">{
				data.map((b, index)=> (
					<div
						key={index}
						onClick={ () => setActiveState(index) }
						className={index === activeState ? 'bullet active' : 'bullet'}></div>
				))
			}</div>
		</section>
	)
}

export default Testimonials
