import vertrieb from '../../images/jobs-vertrieb.jpg'
import telekommunikation from '../../images/jobs-telekommunikation.jpg'
import kundenservice from '../../images/jobs-kundenservice.jpg'

export const data = [
	{
		job: "Für den aktiven Vertrieb",
		image: vertrieb,
		text: "Du sprichst aktiv Kunden an, um Produkte und Dienstleistungen vorzustellen und zu vermarkten."
	},
	{
		job: "Telekommunikation",
		image: telekommunikation,
		text: "Für unterschiedliche Premium-Anbieter aus den Bereichen Festnetz und Mobilfunk führst Du aktiv Kundengespräche, mit dem Ziel Produkte und Angebote zur Vertragsverlängerung zu platzieren."
	},
	{
		job: "Kundenservice",
		image: kundenservice,
		text: "Service am Kunden ist Deine Mission? Telefonisch, schriftlich und auf den unterschiedlichen Online-Kanälen berätst und unterstützt Du Kunden bei Ihren Anliegen."
	},
]
