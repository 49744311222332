import React from 'react'

import './Button.sass'

import AnchorLink from 'react-anchor-link-smooth-scroll'

const Button = (props) => {
	return (
		<AnchorLink href={props.href} className={`main--button ${props.type || ''}`}>
			{props.children}
		</AnchorLink>
	)
}


export default Button
