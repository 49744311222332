import React from 'react'

import './Testimonial.sass'

const Testimonial = ({ text, image, testimonial, job, active, index, handleClick }) => {
	
	
	return (
		<article onClick={ () => handleClick(index) } className={ active ? 'testimonial--container active' : 'testimonial--container'}>
			<div className="speech">
				<span className="apostroph">“</span>
				<em>{text}</em>
			</div>
			<figure className="testimonial">
				<img src={image} alt={ testimonial }/>
				<figcaption>
					<strong>{testimonial}</strong>
					{ job }
				</figcaption>
			</figure>
		</article>
	)
}

export default Testimonial
