import React, { useState } from 'react'
import axios from 'axios'

import './Form.sass'

const Form = ({isSent, setIsSent}) => {

	const [formValues, setFormValues] = useState({
		name: "",
		nachname: "",
		email: "",
		telefon: "",
		urlparameter: window.location.search.substring(1),
		page: window.location.origin,
		consent: false,
		kontakt: false,
		error: ""
	})

	

	const handleFormSubmit = (e) => {
		e.preventDefault()

		if (formValues.name === "" ||
			formValues.nachname === "" ||
			formValues.email === "" ||
			formValues.telefon === "" ||
			!formValues.kontakt ||
			!formValues.consent) {
			setFormValues({ ...formValues, error: "Bitte alle Felder ausfüllen" })
			return
		}

		let formData = new FormData()
		formData.append('name', formValues.name)
		formData.append('nachname', formValues.nachname)
		formData.append('email', formValues.email)
		formData.append('telefon', formValues.telefon)
		formData.append('page', formValues.page)
		formData.append('urlparameter', formValues.urlparameter)

		axios({
			method: 'post',
			url: 'https://kikxxl1.2do-digital.dev/handleSubmit.php',
			data: formData,
			config: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		})
			.then(response => {
				setFormValues({
					name: "",
					nachname: "",
					email: "",
					telefon: "",
					urlparameter: window.location.search.substring(1),
					page: window.location,
					consent: false,
					kontakt: false,
					error: ""
				})
				setIsSent(true)
			})
			.catch(error => setFormValues({...formValues, error: error.response.data}))
	}
	return (
	<>
		{
			(<form>
			<label>
				Vorname*
				<input type="text" name="name" placeholder="Vorname"value={formValues.name} onChange={(e) => setFormValues({...formValues, name: e.target.value})}/>
			</label>
			<label>
				Nachname*
				<input type="text" name="nachname" placeholder="Nachname" value={formValues.nachname} onChange={(e) => setFormValues({ ...formValues, nachname: e.target.value })} />
			</label>
			<label>
				E-Mail-Adresse*
				<input type="email" name="email" placeholder="Email"value={formValues.email} onChange={(e) => setFormValues({...formValues, email: e.target.value})}/>
			</label>
			<label>
				Telefonnummer
				<input type="text" name="telefon" placeholder="Telefon"value={formValues.telefon} onChange={(e) => setFormValues({...formValues, telefon: e.target.value})}/>
			</label>
			<input type="hidden" name="page" value={formValues.page}/>
			<input type="hidden" name="urlparameter" placeholder="URL Parameter" value={formValues.urlparameter} readOnly />
			
			<label className="cb--group">
				<input
					type="checkbox"
					name="consent"
					id="consent"
					checked={formValues.consent}
					onChange={e => setFormValues({ ...formValues, consent: e.target.checked })} />
				<p><span>Ich habe die &nbsp;<a href="https://www.kikxxl.de/impressum/" target="_blank" rel="noopener noreferrer"> Datenschutzhinweise</a>&nbsp; gelesen und bin damit einverstanden.*</span></p>
			</label>
			
			<label className="cb--group">
				<input
					type="checkbox"
					name="kontakt"
					id="kontakt"
					checked={formValues.kontakt}
					onChange={e => setFormValues({ ...formValues, kontakt: e.target.checked })}
				/>
				<p>Ich bin damit einverstanden, dass meine Daten für zukünftig zu besetzende Stellen und damit verbundene Kontaktversuche (Telefon, E-Mail, SMS) für maximal 12 Monate gespeichert werden.*</p>
			</label>

			<button className="main--button" onClick={e => handleFormSubmit(e)}>Jetzt bewerben</button>
			<p>* Pflichtfelder</p>
					<strong style={{ display: 'block', padding: "20px", color: "red" }} dangerouslySetInnerHTML={{ __html: formValues.error }}></strong>
</form>)
	}
		
	</>)
}

export default Form
