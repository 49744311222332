import React, { useState, useEffect } from 'react'

import './Jobs.sass'
import { data } from './JobData'
import Job from './Job/Job'
import Button from '../UIElements/Button/Button'

const Jobs = () => {

	const [activeState, setActiveState] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {			
			if (activeState < (data.length - 1)) setActiveState(activeState + 1) 
			else setActiveState(0)
		}, 10000);
		return () => clearInterval(interval)
	}, [activeState]) 

	return (
		<section id="jobs" className="content">
			<h3>Werde ein Teil unseres Teams – als telefonsicherer Kundenberater (m/w/d)</h3>
			<h4>Du hast die Wahl!</h4>
			<div className="jobs--grid">
				{data.map((d, index) => (
					<Job
						key={index}
						title={d.job}
						text={d.text}
						image={d.image}
						index={index}
						active={activeState === index ? true : false}
						clickHandler={setActiveState}
					/>
				))}
			</div>
			<div className="bullets">{
				data.map((b, index)=> (
					<div
						key={index}
						onClick={ () => setActiveState(index) }
						className={index === activeState ? 'bullet active' : 'bullet'}></div>
				))
			}</div>
			<Button href="#bewerben">Jetzt bewerben!</Button>
		</section>
	)
}

export default Jobs
