import React, { useState } from 'react'
import Form from '../Form/Form'
import FormMessage from '../FormMessage/FormMessage'

import image from '../../images/img-kontakt.jpg'

import './Bewerben.sass'

const Bewerben = () => {

	const [isSent, setIsSent] = useState(false)

	return (
		<section id="bewerben">
			<div className="left--content">
				<div className="form--content">
					<h2>Deine Bewerbung</h2>
					<p>Viele Dank für Deine Bewerbung und Dein damit verbundenes Interesse an einer Tätigkeit bei KiKxxl!</p>
					<Form isSent={isSent} setIsSent={ setIsSent }/>
				</div>
			</div>
			<div className="right--content" style={{backgroundImage: `url(${image})`}}></div>
			<FormMessage active={ isSent }/>
		</section>
	)
}

export default Bewerben
