import React, { useState } from 'react'
import Bubble from '../../UIElements/Bubble/Bubble'

import './Vorteil.sass'

import info from '../../../images/icn-info.svg'
import close from '../../../images/icn-close.svg'

const Vorteil = (props) => {

	const [showOverlay, setShowOverlay] = useState(false)
	const handleOverlayToggle = () => {
		if(window.innerWidth < 960) setShowOverlay(!showOverlay)
	}
	const handleMouseEnter = () => {if(window.innerWidth >= 960) setShowOverlay(true)}
	const handleMouseLeave = () => {if(window.innerWidth >= 960) setShowOverlay(false)}
	return (
		<article
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={handleOverlayToggle}
			>
			<div className="content">
				<img src={ info } alt="Info" className="info"/>
				<img src={props.image} alt={props.title} />
				<h3>{ props.title }</h3>
			</div>
			{showOverlay &&
				<div className="overlay">
				<img className="close" src={ close } alt="Close"/>
				<Bubble align="right"><p>{ props.text }</p></Bubble>
				</div>
			}
			
		</article>
	)
}

export default Vorteil
