import React from 'react'

import './Vorteile.sass'

import Vorteil from './Vorteil/Vorteil'

import icnAuszeichnung from './../../images/icn-auszeichnung.svg'
import icnStandorte from './../../images/icn-standorte.svg'
import icnBenefits from './../../images/icn-benefits.svg'
import icnGehalt from './../../images/icn-gehalt.svg'
import icnEinarbeitung from './../../images/icn-einarbeitung.svg'
import image from './../../images/img-vorteile.jpg'

const getOrt = new URLSearchParams(window.location.search).get("ort");

const data = [
	{
		image: icnAuszeichnung,
		title: 'Wir sind ausgezeichnet',
		text: 'Seit mehreren Jahren wird KiKxxl von der Universität St. Gallen im Wettbewerb „Top Arbeitgeber des Jahres“auf die vorderen Plätze gewählt. Im Jahr 2019 belegen wir erneut Platz 1 und sind „Bester Arbeitgeber des Jahres“ in unserer Größenklasse.'
	},
	{
		image: icnStandorte,
		title: 'Viele Standorte',
		text: 'All unsere Standorte in Osnabrück, Bremen, Dortmund, Bochum und Recklinghausen verfügen über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
	},
	{
		image: icnBenefits,
		title: 'Geniale Benefits',
		text: 'Als KiKxxl Mitarbeiter hast Du Zugriff auf eine ganze Reihe von attraktiven Benefits: Von sehr günstigen Sportangeboten und betrieblicher Altersvorsorge bis zu Weight Watchers at Work-Kursen.'
	},
	{
		image: icnGehalt,
		title: 'Gutes Gehalt',
		text: 'Du erhältst bei KiKxxl in der aktiven Kundenansprache eine leistungs- und zielorientierte Vergütung. Dein Einstiegs-Gehalt liegt bei 10,- Euro / Stunde und ist mit Prämien nach oben offen. Der Durchschnittslohn inkl. Prämien liegt bei ca. 2.200,- bis 2.400 Euro / Monat.'
	},
	{
		image: icnEinarbeitung,
		title: 'Erstklassige Einarbeitung',
		text: 'Telefonischer Kundenberater (m/w/d) bei KiKxxl – wir bereiten Dich ausführlich auf Deinen neuen Job vor – das macht den Job ideal für Quereinsteiger!'
	},
]

// Ortsspezifische Texte
switch (getOrt) {
	case 'Osnabrück':
		data[1].text = 'Unser Standort in der Mindener Straße verfügt über ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
		data[3].text = 'Du erhältst bei KiKxxl in der aktiven Kundenansprache eine leistungs- und zielorientierte Vergütung. Dein Einstiegs-Gehalt liegt bei 10,- Euro / Stunde und ist mit Prämien nach oben offen. Der Durchschnittslohn inkl. Prämien liegt bei ca. 2.300,- bis 2.500 Euro / Monat'
		break;
	case 'Dortmund':
			data[1].text = 'Unser Standort in der ehemaligen Zechni Minster Stein in Dortmund-Eving verfügt über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
			data[3].text = 'Du erhältst bei KiKxxl in der aktiven Kundenansprache eine leistungs- und zielorientierte Vergütung. Dein Einstiegs-Gehalt liegt bei 10,- Euro / Stunde und ist mit Prämien nach oben offen. Der Durchschnittslohn inkl. Prämien liegt bei ca. 2.300,- bis 2.600 Euro / Monat.'
			break;
	case 'Bremen':
		data[1].text = 'Unser Standort in der Airport City verfügt über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
		data[3].text = 'Du erhältst bei KiKxxl im Kundenservice eine leistungs-und zielorientierte Vergütung. Dein Gehalt ist mit Prämien nach oben offen. Der Durchschnittslohn inkl. Prämien liegt bei ca. 2.200,- bis 2.400 Euro / Monat.'
		break;
	case 'Bochum':
		data[1].text = 'Unser Standort im Jahrunderthaus Bochum verfügt über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
		data[3].text = 'Du erhältst bei KiKxxl in der aktiven Kundenansprache eine leistungs- und zielorientierte Vergütung. Dein Einstiegs-Gehalt liegt bei 10,- Euro / Stunde und ist mit Prämien nach oben offen. Der Durchschnittslohn inkl. Prämien liegt bei ca. 2.200,- bis 2.400 Euro / Monat.'
		break;
	case 'Recklinghausen':
		data[1].text = 'Unser Standort im Herzen der Innenstadt von Recklinghausen (ehemaliges C&A Gebäude) verfügt über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
		data[3].text = 'Du erhältst bei KiKxxl in der aktiven Kundenansprache eine leistungs- und zielorientierte Vergütung. Dein Einstiegs-Gehalt liegt bei 10,- Euro / Stunde und ist mit Prämien nach oben offen. Der Durchschnittslohn inkl. Prämien liegt bei ca. 2.300,- bis 2.700 Euro / Monat.'
		break;
	default:
		data[1].text = 'All unsere Standorte in Osnabrück, Bremen, Dortmund, Bochum und Recklinghausen verfügen über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
		data[3].text = 'Du erhältst bei KiKxxl in der aktiven Kundenansprache eine leistungs- und zielorientierte Vergütung. Dein Einstiegs-Gehalt liegt bei 10,- Euro / Stunde und ist mit Prämien nach oben offen. Der Durchschnittslohn inkl. Prämien liegt bei ca. 2.200,- bis 2.400 Euro / Monat.'
}

const Vorteile = () => {
	return (
 		<section id="vorteile" className="content">
			<h2>Deine Vorteile</h2>
			<div className="vorteile--grid">
				{data.map(vorteil => <Vorteil key={ vorteil.title} title={vorteil.title} image={vorteil.image} text={ vorteil.text}/>
				)}
				<article className="image--article" style={{backgroundImage: `url(${image})`}}></article>
			</div>
		</section>
	)
}

export default Vorteile
