import React from 'react'

import './Job.sass'

const Job = ({ image, title, text, index, clickHandler, active }) => {
	return (
		<article
			style={{backgroundImage: `url(${image})`}}
			className={active ? 'single--job active' : 'single--job'}>
			<div className="job--main">
				<h4>{title}</h4>
				<div className="job--content">{text}</div>
			</div>
		</article>
	)
}

export default Job
