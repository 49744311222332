import React from 'react'

import { FaXing, FaFacebookF, FaLinkedinIn, FaYoutube} from "react-icons/fa"

import './Footer.sass'

const Footer = () => {
	return (
		<footer>
			<div className="social-media--links">
				<a href="https://www.xing.com/companies/kikxxlgmbh" target="_blank" rel="noopener noreferrer"><FaXing style={{ fontSize: "3rem" }}/></a>
				<a href="https://www.facebook.com/KiKxxl" target="_blank" rel="noopener noreferrer"><FaFacebookF style={{ fontSize: "2.5rem" }}/></a>
				<a href="https://www.linkedin.com/company/kikxxl-gmbh/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn style={{ fontSize: "2.8rem" }}/></a>
				<a href="https://www.youtube.com/user/KiKxxlTV" target="_blank" rel="noopener noreferrer"><FaYoutube style={{ fontSize: "3rem" }}/></a>
			</div>
			<a href="https://www.kikxxl.de/impressum/" target="_blank" rel="noopener noreferrer">Impressum | Datenschutz</a>
		</footer>
	)
}

export default Footer
