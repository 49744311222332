import React from 'react'
import { FaWhatsapp } from "react-icons/fa"
import phone from '../../images/phone@2x.png'
import Bubble from '../UIElements/Bubble/Bubble'

import './FormMessage.sass'

const FormMessage = ({ active }) => {
	return (
		<div className="submissionInfo" id={ active ? 'active' : ''}>

			<div className="content">
				<div className="right--container">
					<Bubble align="right-reverse">
						<h3>Vielen Dank für Deine Bewerbung</h3>
					</Bubble>
					<img src={phone} alt="Telefon" width="200" />
					<a href="tel:08000545995" className="white--button">Jetzt kostenfrei anrufen</a>
				</div>
				<div className="left--container">
					<h4>Wir möchten dich gern persönlich kennenlernen!</h4>
					<p>Unser Bewerber-Team wird sich in Kürze telefonisch bei Dir melden, um das weitere Vorgehen mit Dir zu besprechen und einen Termin für ein persönliches Gespräch abzustimmen.</p>
					<h4>Gibt es einen guten Moment für unseren Anruf? Dann teile uns Deinen Terminwunsch gern mit:</h4>
					<div className="buttons">
						<a href="https://www.kikxxl.de/bewerbung/online-bewerbung/?Q=Menue" className="white--button" target="_blank" rel="noopener noreferrer">Termin online</a>
						<a href="https://wa.me/0123456789" className="white--button whatsapp" target="_blank" rel="noopener noreferrer"><FaWhatsapp /> WhatsApp</a>
					</div>
					<h4>Jetzt kurz Zeit? Dann ruf uns gern an!</h4>
					<p>
						Natürlich musst Du nicht lang auf unseren Anruf warten, Du kannst Dich auch gern direkt bei uns melden. Du erreichst uns Montag-Freitag zwischen 9:00 – 18:00 Uhr unter 08000/545995.
					</p>
				</div>
			</div>
		</div>
	)
}

export default FormMessage
