import React from 'react'
import './Navigation.sass'

import { FaXing, FaFacebookF, FaLinkedinIn, FaYoutube} from "react-icons/fa"

import logo from '../../images/kikxxl.png'

const Navigation = () => {
	return (
		<nav>
			<div className="nav--wrapper">
				<div className="logo"><img src={logo} alt="Kikxxl" width="150" height="56" /></div>
				<div className="social-media--links">
					<a href="https://www.xing.com/companies/kikxxlgmbh" target="_blank" rel="noopener noreferrer"><FaXing style={{ color: 'white', fontSize: "3rem" }}/></a>
					<a href="https://www.facebook.com/KiKxxl" target="_blank" rel="noopener noreferrer"><FaFacebookF style={{ color: 'white', fontSize: "2.5rem" }}/></a>
					<a href="https://www.linkedin.com/company/kikxxl-gmbh/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn style={{ color: 'white', fontSize: "2.8rem" }}/></a>
					<a href="https://www.youtube.com/user/KiKxxlTV" target="_blank" rel="noopener noreferrer"><FaYoutube style={{ color: 'white', fontSize: "3rem" }}/></a>
				</div>
			</div>
		</nav>
	)
}

export default Navigation
