import React from 'react'
import './Videokanal.sass'


const Videokanal = () => {
	return (
		<section id="videokanal">
			<div className="content">
				<div className="kanalinfo">
					<div className="kanalinfo--content">
						<h3>KiKxxl Video-Kanal</h3>
						<p>Mehr Informationen zu KiKxxl und dem Job des telefonischen Kundenberaters (m/w/d) erhältst Du in unserem Video Kanal.</p>
					</div>
				</div>
				<div className="videocontent">
					<div className="content--wrapper">
						<div className="iframe--wrapper">
							<iframe width="1246" height="701" src="https://www.youtube.com/embed/TgdOKmta4yI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Videokanal
